import { format } from 'date-fns';
import IntraoralForm from '../components/appointment-form/required-services/intraoral/IntraoralForm';
import { DATE_FORMAT, LONDON_BRANCH_ID, LONDON_FINCHLEY_BRANCH_ID, LONDON_HORNCHURCH_BRANCH_ID, LONDON_NEW_MALDEN_BRANCH_ID, MANCHESTER_BRANCH_ID } from './constants';
import { CBCT, IMAGING2D } from './forms/options/appointmentForm.options';
import moment from 'moment';

/**
 * Mapper function that maps appointment object to appointment-form values.
 * @param appointment
 */
export const getAppointmentFormValues = (appointment) => {
	return {
		patientDetails: {
			patientsTitle: appointment?.patient_title || '',
			patientName: appointment?.patient_full_name || '',
			dateOfBirth: appointment?.patient_dob || '',
			gender: appointment?.patient_gender || '',
			phone: appointment?.patient_phone || '',
			mobile: appointment?.patient_mobile || '',
			email: appointment?.patient_email || '',
			payment: appointment?.payment || '',
			isPossibilityOfPregnancy: appointment?.pregnancy ? true : false,
		},
		cbctDetails: {
			//TODO
			isComingWithRadiographicTemplate: '',
			isDoseReduction: false,
			cbctAreaOfInterest: '',
			clinicalIndications: '',
			scans: [],
			outputs: [],
			justifications: [],
			extras: [],
			teethList: [],
			upperLeft: false,
			upperRight: false,
			lowerLeft: false,
			lowerRight: false,
			allTeeth: false,
		},
		imaging2DDetails: {
			isComingWithRadiographicTemplate: '',
			clinicalIndications: '',
			imaging: [],
			outputs: [],
			justifications: [],
			extras: [],
		},
		intraoralDetails: {
			scanOption: '',
			isComingWithRadiographicTemplate: '',
			areaOfInterest: '',
			teethList: [],
			upperLeft: false,
			upperRight: false,
			lowerLeft: false,
			lowerRight: false,
			allTeeth: false,
			requireInvalisign: '',
			shapeUsername: '',
			shapePassword: '',
			invisalignUsername: '',
			invisalignPassword: '',
			invisalignCode: '',
		},
		// patientConsents: true,
		// patientSubjectToSafeguarding: false,
		// patientAgrees: true,
	};
};

/**
 * map row to value to show on AppointmentDetails
 */
export const getRowToShow = (row, column = '') => {
	if (row === true) {
		return 'Yes';
	}
	if (row === false) {
		return 'No';
	}
	if (!row) {
		return '';
	}
	if (Array.isArray(row)) {
		return row.join(', ');
	}
	if (row instanceof Date) {
		return format(row, DATE_FORMAT);
	}
	if (row instanceof Object) {
		return row.name;
	}
	if (row === 'denture_marked') {
		return 'Denture already marked';
	}
	if (row === 'separate_template') {
		return 'Separate Stent';
	}
	if (row === 'denture_to_be_marked_by_ct_dent') {
		return 'Denture to be marked by CT Dent';
	}
	if (row === 'digital_impression') {
		return 'Digital Impression';
	}
	if (row === 'orthodontic_aligners') {
		return 'Aligners';
	}
	if (row === 'radiographic_template') {
		return 'Radiographic Template';
	}
	if (column === 'patient_booking') {
		if (row === 11) {
			return 'Us to arrange';
		}
		if (row === 13) {
			return 'Patient will arrange';
		}
	}

	return row;
};

export const appointmentServiceCondition = (activeScansCheckboxes, only_in_service) => {
	return (
		(only_in_service === 0) ||
		(activeScansCheckboxes.includes(CBCT) && only_in_service === 3) ||
		(activeScansCheckboxes.includes(IMAGING2D) && only_in_service === 2) ||
		(activeScansCheckboxes.includes(IntraoralForm) && only_in_service === 4)
	)
}

export const REACT_APP_SCANNER_PREFERENCE_ID = process.env.REACT_APP_SCANNER_PREFERENCE_ID ? Number(process.env.REACT_APP_SCANNER_PREFERENCE_ID) : 95;
export const REACT_APP_ICAT_VISION_ID = process.env.REACT_APP_ICAT_VISION_ID ? Number(process.env.REACT_APP_ICAT_VISION_ID) : 1;
export const REACT_APP_DICOM_ID = process.env.REACT_APP_DICOM_ID ? Number(process.env.REACT_APP_DICOM_ID) : 7;

export const cbctServiceCondition = (dyno_3d_scanner, branchId, id) => {
	return (
		(branchId !== MANCHESTER_BRANCH_ID && branchId !== LONDON_NEW_MALDEN_BRANCH_ID && id !== REACT_APP_SCANNER_PREFERENCE_ID) ||
		(
			branchId === MANCHESTER_BRANCH_ID &&
			(
				(!dyno_3d_scanner && id !== REACT_APP_ICAT_VISION_ID && id !== REACT_APP_SCANNER_PREFERENCE_ID) ||
				(dyno_3d_scanner === 0 && id !== REACT_APP_SCANNER_PREFERENCE_ID) ||
				(dyno_3d_scanner === 1 && id !== REACT_APP_ICAT_VISION_ID && id !== REACT_APP_DICOM_ID)
			)
		) ||
		(branchId === LONDON_NEW_MALDEN_BRANCH_ID && id !== REACT_APP_DICOM_ID) ||
		(branchId === LONDON_HORNCHURCH_BRANCH_ID && id === REACT_APP_SCANNER_PREFERENCE_ID)
	)
}

const NEW_BRANCH_LANCH = process.env.REACT_APP_NEW_BRANCH_LANCH;
const USE_NEW_BRANCH = process.env.REACT_APP_USE_NEW_BRANCH;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const filterBranches = (id) => {
	if(ENVIRONMENT === "production"){
		const lunchDay = moment().isSameOrAfter(moment(NEW_BRANCH_LANCH), 'days')
		return (lunchDay && (id < 103 || id === LONDON_HORNCHURCH_BRANCH_ID)) || (!lunchDay && id < 103);
	}else{
		return USE_NEW_BRANCH === "1";
	}
}

export const filterDetailBranch = () => {
	return USE_NEW_BRANCH === "1";
}

export const checkIntraOral = (branchId) => {
	return [LONDON_BRANCH_ID, MANCHESTER_BRANCH_ID, LONDON_HORNCHURCH_BRANCH_ID].includes(branchId);
}

export const checkInvisalign = (branchId) => {
	return [MANCHESTER_BRANCH_ID, LONDON_HORNCHURCH_BRANCH_ID].includes(branchId);
}