import { createSlice } from '@reduxjs/toolkit';

import { getAppointmentStatuses } from '../../services/doctors/appointment-statuses.service';
import {
	appointmentPriceBreakdown,
	appointmentSuccessUpdate,
	archiveAppointment,
	createAppointment,
	fetchAppointment,
	shareAppointmentData,
	updateAppointmentData,
} from '../../services/doctors/appointment.service';
import { IDLE, PENDING } from '../../assets/conf/configurations';
import { getResponseTime, toastError } from '../../utils/globals.utils';
import { fetchAppointmentService } from '../../services/doctors/doctors.service';

export const referPatientSlice = createSlice({
	name: 'referPatient',

	initialState: {
		error: {},
		bookingOptionsLoading: IDLE,
		bookingOptionsData: null,
		bookingLoading: false,
		bookingData: null,
		updateAppointmentLoading: false,
		updateAppointmentData: null,
		appointmentLoading: false,
		appointmentData: null,
		archiveAppointmentLoading: false,
		archiveAppointmentData: null,
		shareAppointmentLoading: false,
		shareAppointmentData: null,
		appointmentServiceLoading: false,
		appointmentServiceData: null,
		appointmentServiceBranchId: null,
		appointmentPriceLoading: false,
		appointmentPriceData: null,
		appointmentSuccessUpdateLoading: false,
		appointmentSuccessUpdateData: null,
	},

	reducers: {
		// * Booking Options
		bookingOptionsLoading(state, action) {
			if (state.bookingOptionsLoading === IDLE) {
				state.bookingOptionsLoading = PENDING;
			}
		},

		bookingOptionsSucceed(state, action) {
			state.bookingOptionsLoading = IDLE;
			state.bookingOptionsData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		bookingOptionsFailed(state, action) {
			if (state.bookingOptionsLoading === PENDING) {
				state.bookingOptionsLoading = IDLE;
				state.error = action.payload;
			}
		},

		// * refer patient / create appointment
		bookingLoading(state) {
			if (!state.bookingLoading) {
				state.bookingLoading = true;
			}
		},

		bookingSucceed(state, action) {
			state.bookingLoading = false;
			state.bookingData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		bookingFailed(state, action) {
			if (state.bookingLoading) {
				state.bookingLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},

		resetBookingData(state) {
			state.bookingData = null;
		},

		// * get appointment
		appointmentLoading(state) {
			if (!state.appointmentLoading) {
				state.appointmentLoading = true;
			}
		},

		appointmentSucceed(state, action) {
			state.appointmentLoading = false;
			state.appointmentData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		appointmentFailed(state, action) {
			if (state.appointmentLoading) {
				state.appointmentLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},

		// * archive appointment
		archiveAppointmentLoading(state) {
			if (!state.archiveAppointmentLoading) {
				state.archiveAppointmentLoading = true;
			}
		},

		archiveAppointmentSucceed(state, action) {
			state.archiveAppointmentLoading = false;
			state.archiveAppointmentData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		archiveAppointmentFailed(state, action) {
			if (state.archiveAppointmentLoading) {
				state.archiveAppointmentLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},

		// * update appointment
		updateAppointmentLoading(state) {
			if (!state.updateAppointmentLoading) {
				state.updateAppointmentLoading = true;
			}
		},

		updateAppointmentSucceed(state, action) {
			state.updateAppointmentLoading = false;
			state.updateAppointmentData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		updateAppointmentFailed(state, action) {
			if (state.updateAppointmentLoading) {
				state.updateAppointmentLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},

		// * share appointment
		shareAppointmentLoading(state) {
			if (!state.shareAppointmentLoading) {
				state.shareAppointmentLoading = true;
			}
		},

		shareAppointmentSucceed(state, action) {
			state.shareAppointmentLoading = false;
			state.shareAppointmentData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		shareAppointmentFailed(state, action) {
			if (state.shareAppointmentLoading) {
				state.shareAppointmentLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},

		// * Appointment service
		appointmentServiceLoading(state) {
			if (!state.appointmentServiceLoading) {
				state.appointmentServiceLoading = true;
			}
		},

		appointmentServiceBranchId(state, action) {
			state.appointmentServiceBranchId = action.payload?.branch_id
		},

		appointmentServiceSucceed(state, action) {
			state.appointmentServiceLoading = false;
			state.appointmentServiceData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		appointmentServiceFailed(state, action) {
			if (state.appointmentServiceLoading) {
				state.appointmentServiceLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},

		// * Appointment price breakdown
		appointmentPriceLoading(state) {
			if (!state.appointmentPriceLoading) {
				state.appointmentPriceLoading = true;
			}
		},

		appointmentPriceSucceed(state, action) {
			state.appointmentPriceLoading = false;
			state.appointmentPriceData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		appointmentPriceFailed(state, action) {
			if (state.appointmentPriceLoading) {
				state.appointmentPriceLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},

		appointmentPriceReset(state) {
			state.appointmentPriceData = null;
		},

		// * Appointment success update
		appointmentSuccessUpdateLoading(state) {
			if (!state.appointmentSuccessUpdateLoading) {
				state.appointmentSuccessUpdateLoading = true;
			}
		},

		appointmentSuccessUpdateSucceed(state, action) {
			state.appointmentSuccessUpdateLoading = false;
			state.appointmentSuccessUpdateData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		appointmentSuccessUpdateFailed(state, action) {
			if (state.appointmentSuccessUpdateLoading) {
				state.appointmentSuccessUpdateLoading = false;
				state.error = action.payload;
			}
			toastError(action?.payload?.response?.data?.responseMessage);
		},
	},
});

export const {
	bookingOptionsLoading,
	bookingOptionsSucceed,
	bookingOptionsFailed,
	bookingLoading,
	bookingSucceed,
	bookingFailed,
	resetBookingData,
	archiveAppointmentLoading,
	archiveAppointmentSucceed,
	archiveAppointmentFailed,
	appointmentLoading,
	appointmentSucceed,
	appointmentFailed,
	updateAppointmentLoading,
	updateAppointmentSucceed,
	updateAppointmentFailed,
	shareAppointmentLoading,
	shareAppointmentSucceed,
	shareAppointmentFailed,
	appointmentServiceLoading,
	appointmentServiceSucceed,
	appointmentServiceBranchId,
	appointmentServiceFailed,
	appointmentPriceLoading,
	appointmentPriceSucceed,
	appointmentPriceFailed,
	appointmentPriceReset,
	appointmentSuccessUpdateLoading,
	appointmentSuccessUpdateSucceed,
	appointmentSuccessUpdateFailed,
} = referPatientSlice.actions;

/*
 * REDUX THUNKS
 * Thunks dispatch action creators
 */

export const getBookingOptionsLoading = (state) =>
	state.referPatient.bookingOptionsLoading;

export const getBookingOptions = (state) =>
	state?.referPatient?.bookingOptionsData?.data?.responseData || [];

export const fetchBookingOptions = (requestParam) => async (dispatch) => {
	dispatch(bookingOptionsLoading());
	try {
		const response = await getAppointmentStatuses(requestParam.branchId);
		dispatch(bookingOptionsSucceed(response));
	} catch (error) {
		dispatch(bookingOptionsFailed(error));
	}
};

// * refer patient / create appointment
export const getBookingRequestStatus = (state) =>
	state.referPatient.bookingLoading;

export const getBookingData = (state) =>
	state?.referPatient?.bookingData?.data?.responseData || null;

export const submitBookingRequest = (requestParam) => async (dispatch) => {
	dispatch(bookingLoading());
	try {
		const response = await createAppointment(requestParam);
		dispatch(bookingSucceed(response));
		return response;
	} catch (error) {
		dispatch(bookingFailed(error));
	}
};

// * get appointment
export const getAppointmentRequestStatus = (state) =>
	state.referPatient.appointmentLoading;

export const getAppointmentData = (state) =>
	state?.referPatient?.appointmentData?.data?.responseData || null;

export const getAppointmentDataRequest = (requestParam) => async (dispatch) => {
	dispatch(appointmentLoading());
	try {
		const response = await fetchAppointment(requestParam);
		dispatch(appointmentSucceed(response));
		return response;
	} catch (error) {
		dispatch(appointmentFailed(error));
	}
};

// * archive appointment
export const archiveAppointmentRequestStatus = (state) =>
	state.referPatient.archiveAppointmentLoading;

export const getArchiveAppointmentData = (state) =>
	state?.referPatient?.archiveAppointmentData?.data?.responseData || null;

export const archiveAppointmentDataRequest = (requestParam) => async (dispatch) => {
	dispatch(archiveAppointmentLoading());
	try {
		const response = await archiveAppointment(requestParam);
		dispatch(archiveAppointmentSucceed(response));
		return response;
	} catch (error) {
		dispatch(archiveAppointmentFailed(error));
	}
};

// * update appointment
export const updateAppointmentRequestStatus = (state) =>
	state.referPatient.updateAppointmentLoading;

export const getUpdateAppointmentData = (state) =>
	state?.referPatient?.updateAppointmentData?.data?.responseData || null;

export const updateAppointmentRequest = (requestParam) => async (dispatch) => {
	dispatch(updateAppointmentLoading());
	try {
		const response = await updateAppointmentData(requestParam);
		dispatch(updateAppointmentSucceed(response));
		return response;
	} catch (error) {
		dispatch(updateAppointmentFailed(error));
	}
};

// * share appointment
export const shareAppointmentRequestStatus = (state) =>
	state.referPatient.shareAppointmentLoading;

export const getshareAppointmentData = (state) =>
	state?.referPatient?.shareAppointmentData?.data?.responseData || null;

export const shareAppointmentRequest = (requestParam) => async (dispatch) => {
	dispatch(shareAppointmentLoading());
	try {
		const response = await shareAppointmentData(requestParam);
		dispatch(shareAppointmentSucceed(response));
		return response;
	} catch (error) {
		dispatch(shareAppointmentFailed(error));
	}
};

// * Appointment service
export const appointmentServiceRequestStatus = (state) =>
	state.referPatient.appointmentServiceLoading;

export const appointmentServiceData = (state) =>
	state?.referPatient?.appointmentServiceData?.data?.responseData || null;

export const getAppointmentServiceBranchId = (state) =>
	state?.referPatient?.appointmentServiceBranchId || null;

export const appointmentServiceRequest = (requestParam) => async (dispatch, state) => {
	dispatch(appointmentServiceLoading());
	try {
		dispatch(appointmentServiceBranchId(requestParam));
		const response = await fetchAppointmentService(requestParam);
		dispatch(appointmentServiceSucceed(response));
		return response;
	} catch (error) {
		dispatch(appointmentServiceFailed(error));
	}
};

// * Appointment price breakdown
export const appointmentPriceRequestStatus = (state) =>
	state.referPatient.appointmentPriceLoading;

export const appointmentPriceData = (state) =>
	state?.referPatient?.appointmentPriceData?.data?.responseData || null;

export const appointmentPriceRequest = (requestParam) => async (dispatch) => {
	dispatch(appointmentPriceLoading());
	try {
		const response = await appointmentPriceBreakdown(requestParam);
		dispatch(appointmentPriceSucceed(response));
		return response;
	} catch (error) {
		dispatch(appointmentPriceFailed(error));
	}
};

// * Appointment success update
export const appointmentSuccessUpdateRequestStatus = (state) =>
	state.referPatient.appointmentSuccessUpdateLoading;

export const appointmentSuccessUpdateData = (state) =>
	state?.referPatient?.appointmentSuccessUpdateData?.data?.responseData || null;

export const appointmentSuccessUpdateRequest =
	(requestParam) => async (dispatch) => {
		dispatch(appointmentSuccessUpdateLoading());
		try {
			const response = await appointmentSuccessUpdate(requestParam);
			dispatch(appointmentSuccessUpdateSucceed(response));
			return response;
		} catch (error) {
			dispatch(appointmentSuccessUpdateFailed(error));
		}
	};

export default referPatientSlice.reducer;
